import { DirectoryCustomAttributeFragment as DirectoryCustomAttribute } from 'graphql/generated';
import { ErrorFields } from 'interfaces/error-fields';
import { DirectoryStepProps } from 'interfaces/step-props';
import { SupportedDirectoryType } from 'interfaces/supported-directory-type';
import { FC } from 'react';
import { stepsAzure } from './steps-azure';
import { stepsBambooHR } from './steps-bamboo';
import { stepsBreatheHr } from './steps-breathe-hr';
import { stepsCyberArk } from './steps-cyberark-scim';
import { stepsFourthHr } from './steps-fourthhr';
import { stepsGenericSCIM } from './steps-generic-scim';
import { stepsGoogleWorkspace } from './steps-google-workspace';
import { stepsHibob } from './steps-hibob';
import { stepsJumpCloud } from './steps-jumpcloud';
import { stepsOkta } from './steps-okta';
import { stepsOneLogin } from './steps-onelogin';
import { stepsPeopleHr } from './steps-people-hr';
import { stepsPingFederate } from './steps-pingfederate';
import { stepsRippling } from './steps-rippling';
import { stepsWorkday } from './steps-workday';

type BaseStepsConfig = {
  errorFields?: ErrorFields;
  providerLabel: string;
};

// The prefered approach to defining steps when they have no specific
// interactions with each other. Can be defaulted to going with this approach
export type ArraySteps = {
  name: string;
  isCustomAttributeMapping?: boolean;
  render: FC<DirectoryStepProps>;
}[];

export type ArrayStepsConfig = BaseStepsConfig & {
  steps: ArraySteps;
  type: 'Array';
};

// Used for special cases where we need to have access to shared state between
// each step. There's more setup work involved here, but greater flexibility
export type SingleComponentSteps = {
  name: string;
}[];

type StepsConfig = {
  [key in SupportedDirectoryType]: ArrayStepsConfig;
};

export const resolveStepsConfig = ({
  directoryCustomAttributes,
}: {
  directoryCustomAttributes: DirectoryCustomAttribute[];
}): StepsConfig =>
  filterDisabledSteps(
    {
      AzureSCIMV2_0: stepsAzure,
      BambooHR: stepsBambooHR,
      BreatheHr: stepsBreatheHr,
      CyberArkScimV2_0: stepsCyberArk,
      FourthHr: stepsFourthHr,
      OktaSCIMV2_0: stepsOkta,
      PingFederateScimV2_0: stepsPingFederate,
      Rippling: stepsRippling,
      GSuiteDirectory: stepsGoogleWorkspace,
      GenericSCIMV1_1: {
        ...stepsGenericSCIM,
        providerLabel: 'Generic SCIM v1.1',
      },
      GenericSCIMV2_0: {
        ...stepsGenericSCIM,
        providerLabel: 'Generic SCIM v2.0',
      },
      Hibob: stepsHibob,
      JumpCloudScimV2_0: stepsJumpCloud,
      OneLoginScimV2_0: stepsOneLogin,
      PeopleHr: stepsPeopleHr,
      Workday: stepsWorkday,
    },
    {
      showDirectoryCustomAttributeMappingStep:
        !!directoryCustomAttributes.length,
    },
  );

const filterDisabledSteps = (
  stepsConfig: StepsConfig,
  {
    showDirectoryCustomAttributeMappingStep,
  }: { showDirectoryCustomAttributeMappingStep: boolean },
): StepsConfig =>
  Object.entries(stepsConfig)
    .map(([key, config]) => {
      const providerKey = key as SupportedDirectoryType;

      return [
        providerKey,
        {
          ...config,
          steps: config.steps.filter(
            ({ isCustomAttributeMapping = false }) =>
              !isCustomAttributeMapping ||
              showDirectoryCustomAttributeMappingStep,
          ),
        },
      ] as const;
    })
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), stepsConfig);
